<template>
    <div class="wrapper">
        <div
            v-if="type === 'service'"
            class="container"
        >
            <div class="title">用户使用服务协议</div>
            <div class="p">协议版本：MSZC-V2021-1</div>
            <div class="p">一、总则</div>
            <div class="p">1.1茅生（www.maosheng.vip）是由苏州食里路智能科技有限公司开发及运营的网格化、系统化的思维和技能训练平台，茅生APP、茅生PC网页、茅生微信小程序、茅生微信公众号等皆为茅生客户端（统称“茅生”）。</div>
            <div class="p">1.2请您在注册及使用茅生之前，仔细阅读并确认您已完全了解本协议的全部条款，特别是黑体加粗的内容您更需审慎阅读，如果您勾选了“同意”即表示完全接受本协议全部条款，本协议将构成您与茅生之间有约束力的法律文件。如果您不同意本协议的任何内容，请您停止注册和使用茅生服务。</div>
            <div class="p">1.3如您未满18周岁，请在法定监护人的监护、指导下阅读本协议和使用本服务。否则，请您不要接受本协议以及进行相关操作。</div>
            <div class="p">二、用户注册</div>
            <div class="p">2.1您在注册账号时，应确保所提交的身份信息的真实性、准确性及完整性；如果身份信息发生变化，您应及时更改。在您完成账号注册后，您应妥善保管账号及其密码，并对任何人利用您的帐号及密码所进行的活动负完全的责任。</div>
            <div class="p">2.2对恶意注册账号，或利用茅生账号进行违法活动、欺骗以及其他违反本协议的行为，茅生有权冻结、注销、删除其账号。</div>
            <div class="p">2.3在注册茅生账号时，您应：</div>
            <div class="p">（1）不使用色情、暴力或侮辱、诽谤他人等违反法律和公序良俗的词语注册帐号；</div>
            <div class="p">（2）不故意冒用他人信息及未经他人合法授权以他人名义为您注册帐号；</div>
            <div class="p">（3）不会利用茅生进行非法活动、或进行侵犯他人权利或损害他人利益的活动；不会以非法方式获取或利用茅生其他用户的信息；</div>
            <div class="p">（4）不会以技术方式攻击或破坏或改变茅生、或干扰其运行；不会以非法方式获取或使用茅生的任何软件、代码或其他技术或商业信息；不会对茅生运行的任何程序进行反向工程、反向编译、反向汇编或改写；</div>
            <div class="p">（5）不会未经许可使用茅生的网站名称、公司名称、商标、商业标识、网页版式或内容、或其他由茅生享有知识产权或权利的信息或资料；不会侵犯茅生的商标权、著作权、专利权、其他知识产权或其他合法权利或权益。</div>
            <div class="p">三、 用户行为</div>
            <div class="p">3.1用户可以在茅生发表评论，但是应遵守以下义务：</div>
            <div class="p">（1）不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；</div>
            <div class="p">（2）不得干扰本平台的正常运转，不得侵入本平台及国家计算机信息系统；</div>
            <div class="p">（3）不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；</div>
            <div class="p">（4）不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；</div>
            <div class="p">（5）不得教唆他人从事本条所禁止的行为；</div>
            <div class="p">（6）不得利用在本平台注册的账户牟取非法利益，包括未经同意，在本平台以任何形式发布商业广告及宣传。</div>
            <div class="p">若用户未遵守以上规定的，本平台将有权不经过通知单方面采取限制使用、冻结账号、删除内容等措施。用户须对自己的行为承担法律责任。</div>
            <div class="p">四、服务收费</div>
            <div class="p">4.1 您充分了解并同意，茅生的部分服务是以收费方式提供的，如您使用收费服务，请遵守相关的协议。茅生会在相关页面上做出提示，如果您拒绝支付该等费用，则不能使用相关的服务。</div>
            <div class="p">4.2 您充分了解并同意，茅生有权自行决定并修订相关的收费标准和规则，该类标准及规则一经发布即生效，并构成本协议的有效组成部分。茅生有权决定茅生所提供的服务的资费标准和收费方式，茅生可能会就不同的服务制定不同的资费标准和收费方式，也可能按照茅生所提供的服务的不同阶段确定不同的资费标准和收费方式。 </div>
            <div class="p">4.3茅生可能会根据实际需要对收费服务的收费标准、方式进行修改和变更，茅生也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，茅生会在相应服务页面进行通知或公告。如果您不同意上述修改、变更或付费内容，则应停止使用该服务。您继续使用相应服务即视为您同意相关内容，茅生无需给予任何形式的补偿和退费。</div>
            <div class="p">五、侵权投诉</div>
            <div class="p">5.1 按照相关法律规定规定，任何第三方认为，用户利用茅生平台侵害其民事权益或实施侵权行为的，被侵权人有权书面通知茅生采取删除、屏蔽、断开链接等必要措施。</div>
            <div class="p">5.2 根据《信息网络传播权保护条例》的规定，任何第三方认为，茅生所涉及的作品、表演、录音录像制品，侵犯自己的合法权益的，可以向茅生提交投诉材料，要求茅生删除该侵权作品，或者断开链接。投诉材料中应当包含下列内容：权利人的姓名（名称）、联系方式和地址；要求删除或者断开链接的侵权作品、表演、录音录像制品的名称和网络地址；权利人的有效权利证明材料。权利人应当对投诉的真实性负责，如果侵权投诉不实，则需承担相应的法律责任。</div>
            <div class="p">六、法律责任</div>
            <div class="p">6.1 用户同意在茅生的言行所存在的风险将完全由其自己承担；因使用本网站产生的一切后果也由其自己承担。茅生不对用户的言论与链接负责，并不对由此引起的任何纠纷与法律诉讼等赔偿、损失和费用负责；</div>
            <div class="p">6.2 茅生不保证网站服务（包括硬件）一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。用户承诺不因茅生更改、更新或提高其服务给您造成的损失或不便而向茅生要求赔偿或补偿。</div>
            <div class="p">6.3 您了解并同意，茅生可能存在由第三方享有权利的内容；您不会违反法律而传播任何您不明确享有合法权利的内容。茅生可以随时依其自身判断指定您不应传播的第三方内容。茅生不保证您接受的任何第三方内容具有合法性或真实性或准确性或安全性，也不保证其构成任何可靠的或全面的知识系统，也不对因存储或传送第三方内容过程中产生的延误、错误、遗漏、不准确、或由此产生的任何直接或间接损害，向您赔偿或补偿或承担法律责任。</div>
            <div class="p">6.4 用户同意保障和维护茅生其它用户的利益，不会利用茅生进行非法活动、或进行侵犯他人权利或损害他人利益的活动；如因用户违反有关法律、法规或本协议项下的任何条款而给茅生或任何其它第三人造成损失，用户同意承担由此造成的损害赔偿责任。</div>
            <div class="p">6.5 您了解并同意，因版权原因需调整、更改或者下线有关作品内容，茅生有权根据实际情况，在不通知用户的情况下中断或终止向用户提供的一项或多项作品内容，对于因为前述原因或其他原因造成用户无法获取所需作品内容，茅生不承担任何责任且无需给予任何形式的补偿和退费。</div>
            <div class="p">七、协议修改</div>
            <div class="p">7.1茅生有权根据实际情况对本协议进行修订，茅生可能通过茅生平台公告、平台通知、平台私信、电子邮箱、手机短信等方式之一或其中的多种方式向用户告知修改详情。如果您继续使用本服务，即视为您已接受修改后的相关内容。如果您不接受修改后的相关内容，应当停止使用相关服务。</div>
            <div class="p">八、终止服务</div>
            <div class="p">8.1 茅生有权根据实际情况在任何时候，基于任何原因，暂时或永久地终止用户的账号、密码使用本网站服务，或删除、转移用户存储、发布在本网站的内容，而无需提前通知，且对用户和任意第三人均无需承担任何责任。 </div>
            <div class="p">8.2 终止用户服务后，用户使用服务的权利随即中止，用户没有权利，茅生也没有义务传送任何未处理的信息或未完成的服务给用户或第三方。您同意，不因本协议或本协议项下茅生所提供的服务中止或终止，而要求茅生网向您作出赔偿或补偿或承担任何其他责任。</div>
            <div class="p">九、其他 </div>
            <div class="p">9.1 本协议签订地为苏州市。</div>
            <div class="p">9.2 本协议的生效、履行、解释及争议的解决，均适用中华人民共和国法律。因本协议解释或执行引起的任何争议，双方应友好协商解决；协商不成时，可将纠纷或争议提交至本协议签订地有管辖权的人民法院裁判。</div>
            <div class="p">9.3 您同意，茅生或司法、行政、检察、仲裁等机构向您发出的任何通知，可通过您在注册或使用本服务时所提供的手机号码、微信、地址、电子邮箱中的任何一种方式，以任何形式向您发出有关通知信息。茅生也可以通过平台内或站内的系统信息或私信的形式向您发送有关通知信息。前述通知信息一旦发出即刻生效。</div>
            <div class="p">9.4 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</div>
            <div class="p">9.5本协议最终解释权归苏州食里路智能科技有限公司所有。</div>

            <div class="title"></div>
            <div class="title">互联网即时通信平台公约协议</div>
            <div class="title">（2024年）</div>
            <div class="p">一、总则	47</div>
            <div class="p">二、个人用户账号管理	47</div>
            <div class="p">（一）实名认证	47</div>
            <div class="p">（二）账号等信息的合法性	48</div>
            <div class="p">（三）账号转让	48</div>
            <div class="p">（四）账号处置	48</div>
            <div class="p">三、企业组织用户账号管理	48</div>
            <div class="p">（一）认证审核	48</div>
            <div class="p">（二）相关账号定义	49</div>
            <div class="p">（三）管理员责任	49</div>
            <div class="p">（四）实人认证	50</div>
            <div class="p">（五）企业账号管理权责	50</div>
            <div class="p">（六）企业组织解散	51</div>
            <div class="p">四、用户使用规范	51</div>
            <div class="p">（一）内容发布规范	51</div>
            <div class="p">（二）使用规范	53</div>
            <div class="p">（三）行为规范	53</div>
            <div class="p">五、群主责任	54</div>
            <div class="p">六、未成年人使用条款	54</div>
            <div class="p">七、个人信息保护	55</div>
            <div class="p">八、用户投诉举报	56</div>
            <div class="p">九、注意事项	56</div>
            <div class="p"></div>
            <div class="p">一、总则</div>
            <div class="p">平台公约是用户使用平台服务的指引性规范和行为准则，适用于使用平台客户端应用程序及相关网站的所有用户。用户要充分阅读、完全理解并接受公约所有条款，否则无权使用平台服务。如用户不同意公约或其中任何条款，应立即停止使用平台服务。用户点击“同意”，或者使用了平台服务，或者以其他任何明示或者默示方式表示接受公约的，均视为已阅读并同意本公约。
                用户在使用平台某一特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”）。上述内容一经正式发布，即为本公约不可分割的组成部分，用户同样应当遵守。用户对前述任何单独协议的接受，即视为用户对本公约全部的接受。</div>
            <div class="p">二、个人用户账号管理</div>
            <div class="p">（一）实名认证。用户在使用服务前，需要注册或登录平台账号，并按照国家相关法律法规的要求完成真实身份信息认证。用户在注册、使用平台时，应提供真实、准确、合法、有效的身份证明材料及必要信息。
                用户应当及时更新提供的相关信息，确保信息最新、真实、完整、有效，平台将依法对用户信息进行检查核实。若用户未按照要求及时提供信息，或提供的信息存在明显不实或行政司法机关核实提供的信息无效的，平台将发出询问或要求整改的通知，并要求重新认证，直至中止、终止提供部分或全部平台服务，并保留追究相关法律责任的权力。</div>
            <div class="p">（二）账号等信息的合法性。用户使用账号可以设置昵称、头像、签名、留言等信息，也可为建立或者管理、参与的群聊等设置名称、图片、简介等信息。用户应当保证这些信息的内容和形式符合法律法规（本公约中的“法律法规”指用户所属/所处地区、国家现行有效的法律、行政法规、司法解释、地方法规、地方规章、部门规章及其他规范性文件以及对于该等法律法规的不时修改和补充，以及相关政策规定等）、公序良俗、社会公德以及平台规则，且不会侵害任何主体的合法权益。</div>
            <div class="p">（三）账号转让。账号初始申请注册人不得赠与、借用、租用、转让或售卖账号或者以其他方式许可非初始申请注册人使用账号。非初始申请注册人不得通过受赠、继承、承租、受让或者其他任何方式使用账号。</div>
            <div class="p">（四）账号处置。如果违反本公约，或用户发送传播的内容违法违规、侵犯他人权利的，平台有权进行独立判断并采取相应措施，包括但不限于通过技术手段删除、屏蔽相关内容或断开链接等。同时，平台有权视用户的行为性质，采取包括但不限于暂停或终止提供服务，限制、中止、冻结或终止账号的使用，追究法律责任等措施。</div>
            <div class="p">三、企业组织用户账号管理</div>
            <div class="p">（一）认证审核。符合一定条件的企业组织在线提交相关资料、信息以申请企业组织认证。平台根据申请人申请的企业组织类型及提交的资料和信息，自行或者委托第三方进行审核，并根据审核情况确认企业组织的认证结果和认证信息。平台对认证成功的企业组织定期年审，企业组织用户需提供最新的资料和信息。</div>
            <div class="p">（二）相关账号定义</div>
            <div class="p">（1）企业组织管理员。企业组织用户需授权或指定，拥有企业组织用户管理权限的企业组织成员。管理权限包括但不限于管理企业组织通讯录及组织成员、设定子管理员、代表企业组织开通和管理第三方应用、确认和签署相应在线协议、注销企业组织等。企业组织管理员可以为一人或者多人，简称“管理员”。</div>
            <div class="p">（2）企业组织最终用户。指加入企业组织的个人用户。</div>
            <div class="p">（3）企业账号。认证企业组织用户基于人力资源管理、信息安全管控等经营管理目的，通过企业账号技术服务，为最终用户配置的专属企业账号，企业账号归企业组织所有，最终用户可登录企业账号使用平台数字化办公服务。</div>
            <div class="p">（三）管理员责任。管理员要确保已获得企业组织用户的充分授权，并能够充分代表企业组织用户身份行事。管理员应当做到：</div>
            <div class="p">（1）邀请组织成员加入企业组织，应确保向组织成员充分说明依据。</div>
            <div class="p">（2）保守使用平台服务过程中知悉的企业商业MM，保护企业组织成员个人信息和个人隐私及企业权益。</div>
            <div class="p">（3）保障企业组织管理员账号的保密性和信息的安全性，不得以任何方式转让或者提供给他人使用管理员身份账号。</div>
            <div class="p">（4）对最终用户作为企业组织成员身份及基于泛身份的相关权益进行管理。</div>
            <div class="p">（5）对在使用平台服务期间的行为承担责任。</div>
            <div class="p">（四）实人认证。管理员账号及最终用户需完成企业账号的实人认证，否则将不能进行组织外使用行为。若不再继续使用企业账号，可自行联系企业管理员取消该企业账号的实人认证信息。</div>
            <div class="p">（五）企业账号管理权责</div>
            <div class="p">（1）企业账号管控。企业账号由认证企业组织管控，归属企业组织用户所有。管理员有权代表组织企业管理企业账号，包括但不限于向最终用户配置企业账号、查看最终用户企业账号的登录信息和数据安全情况，有权单方停用、封禁和注销最终用户的企业账号等。</div>
            <div class="p">（2）信息安全管控。企业组织用户有权通过SDK、小程序以及企业自建应用等形式加强企业账号的信息安全管控能力，有权发布信息安全管控制度，有权授权管理员代表用人单位实施信息安全管控职责，包括但不限于依据违法违规违纪程度对企业账号采取禁止或撤回文件资料和信息发布、禁止或撤销联系人或组织添加、禁止截屏和下载、限制登录、冻结账号、限制部分或全部功能等信息安全限制措施。</div>
            <div class="p">（3）内容规范管控。企业组织用户有权对组织架构内的最终用户使用企业账号产生的内容（包括但不限于制作、复制、发布、传播）进行管理；如果企业组织用户发现或受到他人投诉组织成员产生的内容有违法违规、违反员工行为守则等情况，有权不经过通知随时对相关内容采取删除、屏蔽等措施，并视行为情节严重程度对企业账号进行处理。</div>
            <div class="p">（4）个人信息处理。企业组织用户因履行合同所必需或是实施人力资源管理所必需，有权收集、处理最终用户登录和使用企业账号的个人信息，并委托平台处理企业组织控制数据及其最终用户个人信息。</div>
            <div class="p">（六）企业组织解散。当管理员账号操作解散企业组织时，平台会根据解散流程，提示管理员解散企业组织产生的企业控制数据被删除的后果。</div>
            <div class="p">四、用户使用规范</div>
            <div class="p">（一）内容发布规范。使用服务时须遵守法律法规，不得制作、复制、发布、传播含有下列内容的信息及外部链接，也不得为制作、复制、发布、传播含有下列内容的信息及外部链接提供便利：</div>
            <div class="p">（1）反对宪法所确定的基本原则的，危害国家安全，泄露国家MM，颠覆国家政权，破坏国家统一的，损害国家荣誉和利益的。</div>
            <div class="p">（2）反政府、反社会，或存在煽动性的涉政言论、散布谣言，扰乱社会秩序，破坏社会稳定。</div>
            <div class="p">（3）煽动民族仇恨、民族歧视，破坏民族团结的，破坏国家宗教政策，宣扬邪教和封建迷信的。</div>
            <div class="p">（4）歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士姓名、肖像、名誉、荣誉的。</div>
            <div class="p">（5）宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的。</div>
            <div class="p">（6）散布暴力、血腥、凶杀、恐怖的。包括但不限于：展示人或动物被杀戮、致残、枪击、针刺或其他伤害的真实图片，描述暴力或虐待儿童的，或包含宣扬暴力血腥。</div>
            <div class="p">（7）散布淫秽、色情或低俗的，包括但不限于：传播含有淫秽、色情内容，如招嫖、寻找一夜情、性伴侣等内容；传播以色情为目的的情色文学、情色视频、情色漫画等形式的内容；传播非法色情交易的信息等；传播直接或隐晦表现性行为、或以带有性暗示、性挑逗的信息；传播非法性药品、性保健品、性用品和性病治疗营销信息等相关内容的；传播相关部门禁止传播的色情和有伤社会风化的文字、音视频内容的。</div>
            <div class="p">（8）散布赌博的，包括但不限于：传播以虚拟货币或真实货币直接进行押输赢、竞猜、参与赌博等内容的；其他被认定为宣扬赌博色彩的行为。</div>
            <div class="p">（9）教唆犯罪的。</div>
            <div class="p">（10）侮辱或者诽谤他人，侵害他人合法权益的。</div>
            <div class="p">（11）散布谣言，扰乱经济秩序和社会秩序的。</div>
            <div class="p">（12）发布的内容应当以积极健康、具有正向价值、有益于精神文明建设为准则，不得通过对数据、流量进行造假、刷量等方式进行炒作。</div>
            <div class="p">（二）使用规范。使用服务过程中不得从事下列行为：</div>
            <div class="p">（1）将有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料，加以上载、张贴、发送电子邮件或以其他方式传送。</div>
            <div class="p">（2）干扰或破坏本服务与本服务相连线之服务器和网络，或违反任何关于本服务连线网络之规定、程序、政策或规范。</div>
            <div class="p">（3）通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的。</div>
            <div class="p">（4）通过非平台公司开发、授权的第三方软件、插件、外挂、系统，登录或使用软件及服务，或制作、发布、传播上述工具。</div>
            <div class="p">（5）自行、授权他人或利用第三方软件对本软件及其组件、模块、数据等进行干扰。</div>
            <div class="p">（三）行为规范。使用服务过程中不得从事下列行为：</div>
            <div class="p">（1）提交、发布虚假信息，或冒充、利用他人名义的。</div>
            <div class="p">（2）诱导其他用户点击链接页面或分享信息的。</div>
            <div class="p">（3）虚构事实、隐瞒真相以误导、欺骗他人的。</div>
            <div class="p">（4）侵害他人肖像权、隐私权、知识产权、商业MM等合法权利的行为。</div>
            <div class="p">（5）利用平台账号或服务从事任何违法犯罪活动的。</div>
            <div class="p">（6）制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的。</div>
            <div class="p">（7）从事违反法律法规规定，侵犯其他用户合法权益、干扰产品正常运营或平台未明示授权的行为。</div>
            <div class="p">（8）违反法律法规底线、社会主义制度底线、国家利益底线、公民合法权益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”要求的。</div>
            <div class="p">（9）相关法律法规或本协议、相关协议、规则等禁止的。</div>
            <div class="p">同时，用户在使用服务过程中应当遵守当地相关的法律法规，并尊重当地的道德和风俗习惯。</div>
            <div class="p">五、群主责任</div>
            <div class="p">互联网群组建立者、管理者应当履行群组管理责任，依据法律法规和平台公约，规范群组网络行为和信息发布，构建文明有序的网络群体空间。互联网群组成员在参与群组信息交流时，应当遵守法律法规，文明互动、理性表达。</div>
            <div class="p">六、未成年人使用条款</div>
            <div class="p">若用户未满18周岁，则为未成年人，应在监护人监护、指导下阅读本公约和使用本服务。</div>
            <div class="p">未成年人用户涉世未深，容易被网络虚像迷惑，且好奇心强，遇事缺乏随机应变的处理能力，很容易被别有用心的人利用而又缺乏自我保护能力。因此，未成年人用户在使用本服务时应注意以下事项，提高安全意识，加强自我保护：</div>
            <div class="p">（1）认清网络世界与现实世界的区别，避免沉迷于网络，影响日常的学习生活。</div>
            <div class="p">（2）填写个人资料时，加强个人保护意识，以免不良分子对个人生活造成骚扰。</div>
            <div class="p">（3）在监护人或老师的指导下，学习正确使用网络。</div>
            <div class="p">（4）避免陌生网友随意会面或参与联谊活动，以免不法分子有机可乘，危及自身安全。</div>
            <div class="p">监护人、学校均应对未成年人使用本服务多做引导。特别是家长应关心子女的成长，注意与子女的沟通，指导子女上网应该注意的安全问题，防患于未然。</div>
            <div class="p">已满18周岁的成年人因任何原因不具备完全民事行为能力的，参照适用本协议之未成年人使用条款之相关约定。</div>
            <div class="p">七、个人信息保护</div>
            <div class="p">平台致力于个人信息的保护，在用户使用平台过程时，可能需要提供个人信息，以便获取更好的服务和技术支持。平台会遵守法律法规要求收集、处理个人信息，并运用加密技术、匿名化处理等其他与产品及服务相匹配的安全技术措施保护个人信息。同时依法保护用户浏览、修改、删除、拒绝提供相关个人信息以及撤回授权的权利。平台非常重视对未成年人个人信息的保护，若用户不具备完全民事行为能力，在使用平台服务前，应事先取得监护人的同意。</div>
            <div class="p">平台可能会根据推荐算法，向用户个性化推荐内容，用户对个性化推荐具有自主选择及控制权。</div>
            <div class="p">八、用户投诉举报</div>
            <div class="p">平台设立公众投诉、举报平台，用户可以按照平台公示的投诉举报制度进行投诉、举报各类违反法律法规、本公约约定的行为及内容，平台将及时受理和处理投诉、举报，以共同营造风清气正的网络空间。</div>
            <div class="p">九、注意事项</div>
            <div class="p">任何情况下，不应轻信借款、索要密码或其他涉及财产的信息。涉及财产操作的，请一定先核实对方身份，并请经常留意平台有关防范诈骗犯罪的提示。</div>

            <div class="footer">苏州食里路智能科技有限公司</div>
        </div>
        <div v-if="type === 'privacy'" class="container">
            <div class="title">信息保护与隐私政策协议</div>
            <div class="p">协议版本：MSYS-V2021-1</div>
            <div class="p">协议发布日期：2022年04月12日</div>
            <div class="p">协议更新日期：2022年04月16日</div>
            <div class="p">协议生效日期：2022年04月16日</div>
            <div class="p">一、总则</div>
            <div class="p">1.1请您在注册及使用茅生服务前，审慎阅读并充分理解本协议，特别是黑体加粗的内容，当您勾选“同意”时，则表示您完全理解并同意接受本协议全部内容。如果您不同意本协议的任何内容，您应立即停止注册及使用茅生服务。茅生是一款由苏州食里路智能科技有限公司（注册地为苏州市南环东路935号501室，用户信息保护人联系方式为13276367607）提供知识分享服务的产品。</div>
            <div class="p">二、 信息收集</div>
            <div class="p">2.1为帮助您完成注册及使用相关服务，茅生会向您收集如下必要信息：</div>
            <div class="p">（1）您在注册茅生平台帐号时向茅生提供的<strong>手机号码</strong>或<strong>电子邮箱</strong>、设置的<strong>账户密码</strong>。</div>
            <div class="p">（2）为向您提供特定产品和服务，个人用户可能需要向茅生提供<strong>真实姓名、身份证号码、银行卡、电话号码、联系地址、电子邮箱、头像和简介</strong>等信息，企业用户可能需要向茅生提供<strong>企业名称、营业执照、法定代表人身份信息、银行卡、电话号码、联系地址、电子邮箱、商标、简介</strong>等信息。如果您不使用特定产品和服务，则无需提供相关信息。</div>
            <div class="p">2.2您在使用茅生时，根据您的行为和产品特点，茅生将基于以下目的访问您的个人信息及手机上的以下必要内容，开通相应权限：获取茅生内容信息而访问<strong>网络</strong>；录制音频而获取用户<strong>麦克风</strong>；上传头像时使用用户<strong>相册</strong>；录制或上传视频时获取用户的<strong>摄像头或相册、视频</strong>；获取服务或产品更新提醒使用通知；使用本地化服务或线下服务而获取用户<strong>定位信息</strong>。 </div>
            <div class="p">2.3为更好的为您提供服务，茅生可能会记录您在使用服务时提供、形成或留存的信息，包括但不限于<strong>设备信息、日志信息、位置信息、评论信息、唯一应用程序编号、网络和连接信息、Cookie数据、访问数据、播放记录、消费记录、收藏记录</strong>等。 </div>
            <div class="p">2.4若您通过第三方登入茅生的，茅生将通过第三方获取您的昵称和头像，其他的信息以您授权第三方共享的为准。</div>
            <div class="p">2.5为确保网站正常运转、为您获得更轻松的访问体验、向您提供更好的服务，茅生会在您的计算机或移动设备上存储Cookie、Flash Cookie或浏览器或关联应用程序提供的其他通常包含标识符、站点名称以及一些号码和字符的本地存储（统称“Cookie”）。</div>
            <div class="p">三、信息使用</div>
            <div class="p">3.1除下列情形外，茅生不会与任何其他第三方共享您的个人信息：</div>
            <div class="p">（1） 在获得您的授权或明确同意后，茅生才会与其他公司、组织和个人分享您的个人信息。只有您选择同意，茅生才会共享您的个人敏感信息。获得您明确同意或基于您的主动选择，茅生才可能会公开披露您的个人信息。</div>
            <div class="p">（2）茅生可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。</div>
            <div class="p">（3）茅生可能委托授权合作方向您提供某些服务或代表茅生履行相关职责，出于此类正当理由，合作方可能使用您的信息，但授权合作方只能接触到为其履行职责所需信息，且不得将此信息用于其他任何目的。</div>
            <div class="p">（4）为促成纠纷的解决，茅生可能向涉及纠纷的第三方提供您的信息，但所提供的信息将严格受限于解决纠纷之目的，同时茅生也会约束有关第三方依法合规使用您的信息。</div>
            <div class="p">3.2为优化产品或服务，茅生将使用您提供的信息，自行或授权数据服务商进行数据统计、分析，以评估、维护，和改进茅生平台、产品或服务的性能，进行必要的业务运营，参与有关平台和服务的市场调研活动，开发新的平台、产品或服务，提供客户支持，进行茅生产品的市场推广和宣传。</div>
            <div class="p">3.3当茅生发生重组、合并、分立、清算或资产出售时，您的信息将作为交易标的转移到新的主体，在本协议变更前，您的个人信息仍然受到本协议的保护。</div>
            <div class="p">四、 信息管理</div>
            <div class="p">4.1您可以访问、更正及删除自己的账户信息或使用茅生的服务时您提供的其他个人信息。</div>
            <div class="p">4.2您可以自行注销账户，无法自行注销帐号的，可以联系茅生客服，协助您完成注销账户操作。</div>
            <div class="p">4.3如您发现收到了不属于您主动搜索、浏览的内容和作品、服务信息推送。如您不希望接收上述信息，可通过原路径进行退订，例如短信推送可以直接回复相关的退订口令。 </div>
            <div class="p">五、信息存储</div>
            <div class="p">5.1茅生在全球运营平台及服务时就收集到的信息将统一默认存储在中华人民共和国境内的服务器内。 </div>
            <div class="p">六、信息保护</div>
            <div class="p">6.1茅生将采取符合业界标准、合理可行的安全防护措施保护您提供的个人信息安全，包括但不限于运用各种安全技术和程序建立完善的管理制度、采用专业加密存储和传输方式等，防止您的个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。 </div>
            <div class="p">6.2账户的注销</div>
            <div class="p">
                <div class="p-sub">6.2.1注销方式：我的->设置->账号管理->账号注销</div>
                <div class="p-sub">6.2.2 您注销账户后，您的个人信息在茅生前台将不可检索、访问。您知晓并理解，我们将按照《中华人民共和国网络安全法》等法律法规的规定留存你的相关信息；超出必要保存期限后，我们将删除或匿名化处理你的个人信息</div>
                
            </div>
            <div class="p">6.3合作伙伴SDK或相关技术获取用户信息情况披露</div>
            <div class="p">
                <div class="p-sub">6.3.1 SDK名称：友盟+SDK<br/>
                        服务类型：如统计分析 ,社会化分享,应用性能监控平台U-APM<br/>
                        个人信息收集范围：应用列表、设备信息（IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/IP地址/SIM 卡 IMSI /位置/ICCID/设备序列号）<br/>
                        个人信息收集目的:  用于唯一标识设备，以便提供设备应用性能监控服务；通过采集位置信息提供反作弊服务，剔除作弊设备，排查应用性能崩溃原因。<br/>
                        个人信息收集方式: sdk本机采集<br/>
                        隐私权政策链接：https://www.umeng.com/page/policy</div>
                
                <div class="p-sub">
                        6.3.2 SDK名称：支付宝SDK<br/>
                        服务类型：应用移动支付<br/>
                        合作方：蚂蚁金服网络技术有限公司<br/>
                        个人信息收集范围：设备标识信息（IMSI、IMEI、Android ID、MAC地址、BSSID、SSID）,系统运营信息、网络状态信息、iOS广告标识符（IDFA）、MAC地址、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、国际移动用户识别码（IMSI）、应用列表信息、基站信息、社交平台OpenID、地理位置<br/>
                        个人信息收集目的:  用于App 运行、网络请求、账号注册及登录等安全防护<br/>
                        个人信息收集方式: sdk本机采集<br/>
                        隐私政策：https://render.alipay.com/p/yuyan/180020010001196791/preview.html?agreementId=AG00000132
                </div>

                <div class="p-sub">6.3.3 SDK名称：微信openSDK<br/>
                        服务类型：嵌入第三方SDK，SDK收集传输个人信息<br/>
                        合作方：腾讯科技（深圳）有限公司<br/>
                        个人信息收集范围：设备MAC地址、唯一设备标识码、位置信息、应用列表<br/>
                        个人信息收集目的:  社交平台分享<br/>
                        个人信息收集方式: SDK本机采集<br/>
                        隐私政策：https://privacy.qq.com/policy/tencent-privacypolicy
                </div>

                <div class="p-sub">6.3.4. 阿里云号码认证SDK<br/>
                    服务类型：本机号码一键登录和认证<br/>
                    收集信息类型：网络类型、设备信息（含IP地址、设备制造商、设备型号、手机操作系统、SIM卡信息SIM State、SIM卡信息ICCID）<br/>
                    隐私政策链接: https://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud202112211045_86198.html
                </div>

                <div class="p-sub">6.3.5.小米推送SDK<br/>
                    使用目的:用于向您推送消息<br/>
                    信息获取类型:设备标识(OAID、加密的Android ID)、推送消息内容、设备信息(设备厂商、型号、归属地、运营商名称等)、通知栏设置<br/>
                    隐私政策链接:https://dev.mi.com/console/doc/detail?pId=1822
                </div>

                <div class="p-sub">6.3.6.OPUSH SDK<br/>
                    使用目的：推送通知栏消息<br/>
                    使用方式：去标识，加密传输的安全方式<br/>
                    收集数据类型：设备信息、应用信息、网络信息<br/>
                    隐私政策链接:https://open.oppomobile.com/new/developmentDoc/info?id=10288
                </div>
            </div>
            <div class="p">七、协议修订</div>
            <div class="p">7.1茅生可能对本协议进行不定期修改，协议修改后，茅生可能通过茅生平台公告、平台通知、平台私信、电子邮箱、手机短信等方式之一或其中的多种方式向用户告知修改详情。如果您不同意修改后的协议，请立即停止使用茅生的服务；如果您在协议修改后继续使用茅生服务，则视为您已接受本协议的修改。 </div>
            <div class="p">其他</div>
            <div class="p">8.1本协议签订地为苏州市。</div>
            <div class="p">8.2与本协议的订立、解释、执行等相关的一切争议或纠纷，应友好协商，协商不成的，可将争议提交协议签订地有管辖权的人民法院裁判。</div>
            <div class="p">8.3本协议的最终解释权归苏州食里路智能科技有限公司所有。</div>

            <div class="footer">苏州食里路智能科技有限公司</div>
        </div>
        <div v-if="type === 'creator'" class="container">
            <div class="title">创作者服务协议</div>
            <div class="p">协议版本：MSCZ-V2021-1</div>
            <div class="p">总则</div>
            <div class="p">1.1如您希望在茅生发布音频或视频等作品，获得“创作者”身份，请您务必审慎阅读、充分理解各条款内容，特别是黑体加粗条款内容。除非您已阅读并接受本协议所有条款，否则您无权使用茅生提供的发布作品服务。当您勾选“同意”时，即视为您已阅读并完全同意本协议内容。如果您不同意本协议的任何内容，请您停止申请开通“创作者”操作，或者停止使用与“创作者”相关的服务。</div>
            <div class="p">1.2 应法律和平台治理要求，当您注册成为茅生“创作者”后，您应当按照要求进行实名认证操作，个人用户应当向茅生提供真实姓名、身份证及其信息等，企业用户应当向茅生提供企业名称、营业执照、法定代表人身份证及信息等。如果您不同意实名认证，您将无法使用茅生的作品发布服务。</div>
            <div class="p">二、作品要求</div>
            <div class="p">2.1用户在茅生发布作品时，必须遵守国家有关法律规定，并承担一切因自己发布信息不当导致的民事、行政或刑事法律责任。用户在茅生发布的作品，不得含有以下内容：</div>
            <div class="p">（1）违反宪法确定的基本原则和内容的； （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；（3）损害国家荣誉和利益，攻击党和政府的；（4）煽动民族仇恨、民族歧视，破坏民族团结的； （5）破坏国家、地区间友好关系的；（6）违背中华民族传统美德、社会公德、论理道德、以及社会主义精神文明的； （7）破坏国家宗教政策，宣扬邪教和封建迷信的；（8）散布谣言或不实消息，扰乱社会秩序 ，破坏社会稳定的；</div>
            <div class="p">（9）煽动、组织、教唆恐怖活动、非法集会、结社、游行、示威、聚众扰乱社会秩序的；（10）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</div>
            <div class="p">（11）侮辱或诽谤他人，侵害他人合法权益的；（12）侵犯他人肖像权、姓名权、名誉权、隐私权或其他人身权利的；（13）侵犯他人知识产权、商业秘密、技术秘密的；                             </div>
            <div class="p">（14）使用漫骂、辱骂、中伤、恐吓、诅咒等不文明语言的；（15）以非法民间组织名义活动的； （16）其他法律、法规禁止的内容。</div>
            <div class="p">2.2若用户拟发布的作品及其内容不符合2.1要求，茅生有权拒绝发布相关作品。已经发布的相关违规作品，茅生可不经过通知该用户直接下架、删除相关作品。经茅生评估，认为用户存在重大违规风险的，茅生有权不经过通知该用户，直接对相关账户采取限制使用、冻结等措施。</div>
            <div class="p">版权约定</div>
            <div class="p">3.1用户承诺，所发布的作品属于自己的原创作品，或经相关权利人授权许可的作品，来源真实、合法，尚未在其他渠道首次发表，不存在侵犯他人权益的情况。</div>
            <div class="p">3.2用户在茅生发布作品后（即首次发表后），与该作品相关的全部财产性权利（包括不限于复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权等）一次性转让给茅生，茅生将在全球、全互联网、永久性对作品享有前述权利，作为回报，茅生将根据该作品的商业运作盈利结果持续向用户支付转让报酬（见本协议第四条“转让报酬”约定，本协议的“转让报酬”或称“创作收入”）。3.3茅生基于受让与作品相关的全部财产性权利，拥有作品的独家经营权和商业上的处置权，并将前述权利转授给茅生合作方的权利。包括不限于：茅生有权将作品用于本平台的商业运作，以及在其它渠道、平台推荐或传播、出租此作品，与其他第三方就作品的商业运作进行合作等。</div>
            <div class="p">3.4未经茅生的书面同意，用户及任何第三方无权行使本协议3.2中约定的已经转让给茅生全部权利中的任何权利。包括不限于：在其他音频/视频网站，发布或授权他人发布、传播、运作已经在茅生发布的作品，否则视为用户违约及侵权，茅生有权下架用户在茅生发布的相关作品或全部作品，并有权要求用户承担违约责任及侵权损失。</div>
            <div class="p">3.5未经茅生的书面同意，用户不得将茅生的作品以任何形式的复制、修改、翻译、改编、汇编等形式制作衍生作品，进行分发、公开展示或商业运作。</div>
            <div class="p">3.6未经茅生的同意，用户不应要求茅生对已发布作品进行删除、修改等不利于作品传播和运作的操作，否则视为用户违约，茅生有权要求违约用户承担其违约行为给茅生平台和其他用户造成的经济损失。</div>
            <div class="p">转让报酬（创作收入）</div>
            <div class="p">4.1茅生对用户消费作品的收费方式为会员制收费，即按年收取固定会员费用，全体付费用户的总付费额构成茅生的总销售收入额。按照当前的平台运营情况，茅生有权拿出总销售收入额的40%，作为茅生受让全部作品财产性权利而向创作者支付的报酬（茅生有权根据实际情况对转让报酬及其比例进行调整）。</div>
            <div class="p">4.2您的创作收入取决于付费用户使用您作品的时长（需去除同一用户的重复使用时间）占全体付费用户使用茅生全部作品时长的比例，比例越大，收入越高。</div>
            <div class="p">4.3茅生也可能通过一次性支付转让报酬，来买断一些作品的版权，这类作品的创作收入需另行订立协议，不适用本协议关于“创作收入”的约定。</div>
            <div class="p">4.4 您在茅生获得的创作收入为增值税含税收入，您有义务向茅生提供增值税发票，或者由茅生配合您向税务机关取得增值税发票；同时茅生会依据法律规定对您的个人所得完成代扣缴。</div>
            <div class="p">五、结算与支付</div>
            <div class="p">5.1茅生将每天对全部作品统一进行一次创作收入结算。</div>
            <div class="p">5.2茅生将按照本协议约定，在扣除您应缴纳的个人所得税后，将您的个人创作收入下发至您的茅生个人账户，您可以对该金额按月实施提现操作。您可以提现上个月，以及上个月更早的月份的收益。</div>
            <div class="p">六、费用扣除</div>
            <div class="p">6.1如果您违反《用户服务及隐私政策协议》或本协议或其他茅生的协议、规则，或侵害他人合法权益，且依法应当承担违约责任或赔偿损失时，茅生有权直接从您的创作收入直接扣除相关金额，并支付至守约方或遭受损失方（包括不限于茅生、其他用户、茅生合作方、被侵权方等）。</div>
            <div class="p">6.2当您的个人账户创作收入余额不足以支付违约金或赔偿金时，不足的部分您应当主动向有关方支付，以免发生法律追偿纠纷。若茅生提前为您垫付了相关违约金或赔偿款时，茅生有权直接从您的创作收入扣除相关金额，或者依法向您继续追偿。</div>
            <div class="p">七、版权保护</div>
            <div class="p">7.1为保障著作权人和/或依法可以行使信息网络传播权的权利人（以下称“权利人”）的合法权益，如权利人认为茅生所提供的信息存储空间、搜索或链接服务存储、搜索或链接的内容所涉及的作品侵犯了其信息网络传播权或者被删除、改变了自己的权利管理电子信息的，可书面通知茅生，要求茅生删除该作品，或者断开该作品的链接。 茅生将积极核实，并根据核实结果，按照相关法律规定采取对应措施。您的通知应当具备下列内容：（1）权利人的姓名（名称）、联系方式、地址、身份证明复印件； （2）要求删除或者断开链接的侵权作品的准确名称和网络地址；（3）认为构成侵权的初步证明材料，包括但不限于权属证明、创作手稿、经权威机构签发的作品创作时间戳等。7.2茅生收到通知后，将会对其进行形式审查，审查通过后将依据《信息网络传播权保护条例》的规定将及时删除涉嫌侵权的作品，或者断开涉嫌侵权作品的链接，并同时将通知书转送作品提供者。如不符合上述条件，茅生会请您继续补足相应信息，且暂不采取包括删除等相应措施。7.3若茅生收到权利人通知，主张用户发布或传播的内容侵犯其相关权利的，茅生有权进行独立判断并采取删除、屏蔽或断开链接等措施。7.4因用户违反本协议而导致任何第三方及茅生遭受损失的，用户应一并赔偿各方损失。</div>
            <div class="p">8、协议修改8.1茅生有权根据实际情况的变化，及茅生业务发展需要，对本协议的条款作出变更或者修改，一旦本协议的内容发生变动，茅生可能通过茅生平台公告、平台通知、平台私信、电子邮箱、手机短信等方式之一或其中的多种方式向您告知修改详情。</div>
            <div class="p">8.2如果您不同意本协议相关条款的修改内容，您应当停止使用茅生中与“创作者”相关的服务，如果您继续使用茅生，则视为您接受茅生对本协议相关内容的所做的修改。</div>
            <div class="p">九、其他</div>
            <div class="p">9.1本协议签订于苏州市。</div>
            <div class="p">9.2 因本协议的订立、执行、解释等产生的纠纷，应友好协商，协商不成的，可以将纠纷或争议提交至本协议签订地有管辖权的人民法院进行裁判。</div>
            <div class="p">9.3本协议的最终解释权归苏州食里路智能科技有限公司所有。</div>

            <div class="footer">苏州食里路智能科技有限公司</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Protocol',
    components: {
        
    },
    data() { 
        return {
            type: 'service'
        }
    },
    mounted() {
        let { type } = this.$route.query;
        debugger
        if (type) {
            this.type = type;
        }
    }
}
</script>

<style
    lang="scss"
    scoped
    src="./index.scss"
>
</style>